<template>
    <div>
      <page-title :heading=heading :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">List of Product Types</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" @click="onCreateItem" class="">
						<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mt-3">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">&nbsp;&nbsp;entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
                        <a-input-search
                            placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"
                            @search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-error mr-3"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>
								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn" style="color: #FB8C00;" @click="onEditItem(record)">
										<a-icon type="form" /> Edit
									</a-button>
									&nbsp;&nbsp;
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn" style="color: #F44335;" @click="showDeletionConfirmation(record.uuid)">
										<a-icon type="delete" theme="outlined"/> Delete
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-2 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-2">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add Product Type' : 'Edit Product Type'}}</h4>
			<a-form-model
				:model="newItem"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="mt-4 mb-4 pb-0" 
					label="Name *"
					:colon="false">
						<a-input 
							v-decorator="['name', { rules: [{ required: true, message: 'Please input your note!' }] }]"
							v-model="newItem.name"/>
				</a-form-item>

				<a-form-item class="mt-4"> 
					<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="py-1">
						{{ newItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>


		<!-- DELETEE CONFIRMATION -->
		<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this record?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'NAME',
			dataIndex: 'name',
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			width: "40%"
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
      components: {
        PageTitle,
      },
      data() {
			return {
            heading: 'Settings',
            subheading: 'Product Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            records: [],
            
            form: this.$form.createForm(this, { name: 'newItemForm' }),
            rules,
            newItem: {
                uuid: null,
                name: null,
                companyUuid: null,
                codeName: null,
                description: null,
                isCreate: true,
                loading: false,
                showModal: false,
            },

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
      },
  
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getProductTypes();
                },
            },
            deep: true,
        },
		created() {
            this.getUserDetails();
			this.getProductTypes();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.pagination.currentPage = 1;
					this.pagination.perPage = 10;
					
					this.getProductTypes()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getProductTypes();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getProductTypes();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getProductTypes();
            },


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },

			async getProductTypes() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/product-types/${companyUuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.records = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            this.notify.text = "There is no data available";
							this.notify.type = "success";
                            this.$notify(this.notify);
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
            },


			onCreateItem() {
                this.newItem.uuid = null;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.name = null;
				this.newItem.codeName = null;
				this.newItem.description = null;
				this.newItem.isCreate = true;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },

			onEditItem(item) {
                this.newItem.uuid = item.uuid;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.name = item.name;
				this.newItem.codeName = item.codeName;
				this.newItem.description = item.description;
				this.newItem.isCreate = false;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },


			async handleSubmit() {

				if(this.newItem.isCreate) {

					this.newItem.loading = true;
		
					let url = `${this.$BACKEND_URL}/product-types`
					
					this.newItem.uuid = undefined;

					this.$AXIOS.post(url, this.newItem).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.newItem.uuid = null;
							this.newItem.name = null;
							this.newItem.codeName = null;
							this.newItem.description = null;
							this.newItem.isCreate = true;
							this.newItem.loading = false;
							this.newItem.showModal = false;

							this.notify.text = response.data.message
							this.notify.type = "success"

							this.$notify(this.notify)

						}

						this.getProductTypes()

					}).catch(error => {
					
						this.newItem.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						this.notify.type = "error"

						this.$notify(this.notify)
			
					});

				}else{

					this.newItem.loading = true;
		
					let url = `${this.$BACKEND_URL}/product-types/${this.newItem.uuid}`

					this.$AXIOS.patch(url, this.newItem).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.newItem.uuid = null;
							this.newItem.name = null;
							this.newItem.codeName = null;
							this.newItem.description = null;
							this.newItem.isCreate = true;
							this.newItem.loading = false;
							this.newItem.showModal = false;

							this.notify.text = response.data.message
							this.notify.type = "success"

							this.$notify(this.notify)

						}

						this.getProductTypes()
		
					}).catch(error => {
					
						this.newItem.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						this.notify.type = "error"

						this.$notify(this.notify)
					});
				}
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},

			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/product-types/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.getProductTypes();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
			},


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>